.page {
  width: 100%;
  flex-grow: 1;
  &__title {
    width: 100%;
    padding-top: .3rem;
    text-align: center;
  }
  &__export {
    margin: 10px 0 0 5px;
  }
}