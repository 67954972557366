.complex-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 2.5em;
  box-sizing: border-box;
  outline: 0;
  padding: 0;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;

  &_withItems {
    padding: 0.5rem;
    row-gap: 0.5rem;
  }

  &_isFile {
    display: flex;
    flex-direction: row-reverse;

  }

  &__add-button {
    color: white;
    background: #FF8D00;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    min-height: 2.5em;
  }

  &__edit {
    width: 2rem;
    margin-left: .4rem;
    cursor: pointer;

    * {
      fill: #FF8D00;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    width: 100%;
    column-gap: 5px;
  }


}