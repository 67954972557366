.file-input {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-size: 1rem;
    background: none;
    color: #FFF;
    border: none;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-height: 2.5em;
    padding: 2px;

    &__isFile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 2px;
        width: 15%;
        height: 100%;
        margin-left: auto;
    }

    &__button {
        display: flex;
        color: white;
        background: #FF8D00;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 1rem;
    }
}