.table-row {
  width: 100%;
  height: 2.5rem;
  display: flex;
  box-sizing: border-box;
  border-top: 1px solid #808080;
  position: relative;
  &__item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.5rem;
    border-right: 1px solid #808080;
    user-select: all !important;
    height: 100%;
    &_withExpander {
      padding-left: 1.5rem;
    }
  }
  &__item-scrollzone {
    width: 100%;
    max-height: 100%;
    overflow: overlay;
  }
  &__edit {
    width: 2rem;
    margin-left: .4rem;
    cursor: pointer;
    * {
      fill: #FF8D00;
    }
  }
  &__buttons-block {
    position: absolute;
    right: .2rem;
    display: flex;
    align-items: center;
  }
  &__link {
    color: #FF8D00;
    text-decoration: underline;
  }

  &__show-clients {
    position: absolute;
    width: 2.5rem;
    height: 1.5rem;
    top: 0.4rem;;
    left: -0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #FF8D00;
    cursor: pointer;
  }
}